<template>
  <div class="game-list-container">
    <div class="games-title">{{ $t("이달의 쿠폰") }}</div>
    <div class="game-cards-container">
      <el-card
        v-for="game in gameList"
        :key="game.title"
        :body-style="{
          marginTop: '25px',
          marginRight: '12px',
          marginLeft: '12px',
          padding: '15px 0 15px 15px',
          width: cardWidth - 15 + 'px',
          // height: '90px',
        }"
        shadow="never"
      >
        <a class="user-item" :href="game.url" target="_blank">
          <img :src="game.cover" class="img-icon" />
        </a>
        <div class="user-item game-card-small">
          <div>{{ game.title }}</div>
          <div class="game-type">
            <img :src="game.client_end === 1 ? pcIcon : phoneIcon" />
            <span>{{ gameTypes[game.game_type] }}</span>
          </div>
          <div class="description">
            {{ $t("쿠폰") }}: {{ game.code }}
            <!-- <div v-html="`${$t('쿠폰')}: ${game.code}`"></div> -->
            <button
              type="button"
              class="EVENT"
              v-clipboard:copy="game.code"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              {{ $t("복사") }}
            </button>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { mapGetters } from "vuex";
import request from "../../../api/request";
import enums from "../../../utils/enums";

export default {
  name: "Grids3",
  data() {
    return {
      gameTypes: enums.GAME_TYPE_CHOICES,
      pcIcon: require("../../../assets/images/icon-pc.png"),
      phoneIcon: require("../../../assets/images/icon-mo.png"),
      gameList: [],
    };
  },
  computed: {
    ...mapGetters(["screenWidth"]),
    cardWidth: {
      get() {
        if (this.screenWidth > 1271) return 296;
        else if (this.screenWidth > 689 && this.screenWidth <= 1271)
          return (this.screenWidth - 97) / 2;
        else return this.screenWidth - 20;
      },
      set() {},
    },
  },
  methods: {
    onCopy(o) {
      Message({
        showClose: true,
        message: o.text + " copied",
        type: "success",
        duration: 1000,
      });
    },
    onError() {},
    getCoupon() {
      let _this = this;
      request.get("/coupons").then(function(res) {
        if (res.status == 200) {
          _this.gameList = res.data.results;
        }
      });
    },
  },
  mounted() {
    this.getCoupon();
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
.description {
  button {
    cursor: pointer;
    // margin-top: 10px;
  }
}
</style>
