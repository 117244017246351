<template>
  <div class="game-list-container">
    <div class="games-title inavtive">
      <span
        :class="selectType === 'all'?'active':'inavtive'"
        @click.prevent="switchType('all')"
      >{{ $t('전체게임') }}</span>
      <span :class="selectType === 1?'active':'inavtive'" @click.prevent="switchType(1)">{{ $t('PC게임') }}</span>
      <span
        :class="selectType === 2?'active':'inavtive'"
        @click.prevent="switchType(2)"
      >{{ $t('모바일게임') }}</span>
    </div>
    <div class="game-cards-container">
      <el-card
        v-for="game in gameList"
        :key="game.name"
        :body-style="{ display: selectType === 'all' || selectType == game.client_end?'block':'none', marginTop: '25px',  marginRight: '12px', marginLeft: '12px', padding: '15px', width:  (cardWidth-30) + 'px' }"
        shadow="never"
      >
        <a class="user-item" :href="game.url" target="_blank">
          <img :src="game.cover" class="img-icon" />
        </a>
        <div class="user-item game-card-small">
          <div>{{ game.name }}</div>
          <div class="game-type">
            <img :src="game.client_end === 1?pcIcon:phoneIcon" />
            <span>{{ gameTypes[game.game_type] }}</span>
          </div>
          <div class="description">
            <img :src="facebookIcon" class="img-pointer" v-if="game.facebook_url" @click="goto(game.facebook_url)"/>
            <img :src="cafeIcon" class="img-pointer" v-if="game.naver_url" @click="goto(game.naver_url)"/>
            <img :src="androidIcon" class="img-pointer" v-if="game.android_url" @click="goto(game.android_url)"/>
            <img :src="appleIcon" class="img-pointer"  v-if="game.apple_url" @click="goto(game.apple_url)"/>
            <img :src="homeIcon" class="img-pointer"  v-if="game.home_url" @click="goto(game.home_url)"/>
            <span :class="game.tag" class="img-pointer" v-if="game.tag">{{ game.tagName }}</span>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import request from '../../../api/request'
import enums from '../../../utils/enums'

export default {
  name: "Grids4",
  data() {
    return {
        gameTypes: enums.GAME_TYPE_CHOICES,
      facebookIcon: require("../../../assets/images/icon-fb.png"),
      cafeIcon: require("../../../assets/images/icon-cf.png"),
      androidIcon: require("../../../assets/images/icon-an.png"),
      appleIcon: require("../../../assets/images/icon-ap.png"),
      homeIcon: require("../../../assets/images/icon-hm.png"),
      pcIcon: require("../../../assets/images/icon-pc.png"),
      phoneIcon: require("../../../assets/images/icon-mo.png"),
      selectType: "all",
      gameList: [
      ],
    };
  },
  computed: {
    ...mapGetters(['screenWidth']),
    cardWidth: {
      get() {
        if (this.screenWidth > 1271) return 296;
        else if (this.screenWidth > 689 && this.screenWidth <= 1271)
          return (this.screenWidth - 97) / 2;
        else return this.screenWidth - 20;
      },
      set() {},
    },
  },
  methods: {
      goto (url) {
          window.open(url, '_blank')
      },
    switchType(text) {
      this.selectType = text;
    },
      getGame () {
          let _this = this
          request.get('/games').then(function (res) {
            if (res.status == 200) {
                _this.gameList = res.data.results
            }
          })
      },
  },
  mounted () {
      this.getGame()
  },
};
</script>
<style>
.games-title span {
  margin-right: 50px;
  cursor: pointer;
}

.inavtive {
  color: #444444;
}

.img-pointer {
    cursor: pointer;
}
</style>
