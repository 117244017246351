<template>
  <div class="game-list-container">
    <div class="games-title">{{ $t('진행중인 이벤트') }}</div>
    <div class="game-cards-container">
      <el-card
        v-for="game in gameList"
        :key="game.title"
        :body-style="{ marginTop: '25px',  marginRight: '12px', marginLeft: '12px', padding: '0px', width: cardWidth + 'px' }"
        shadow="never"
      >
        <a :href="game.url" target="_blank">
          <img :src="game.cover" class="img-heading" />
        </a>
        <div style="padding: 14px;">
          <div>
            <div class="user-item">{{ game.title }}</div>
            <div class="user-item share-butt">
                <img :src="facebookIcon" class="img-pointer" v-if="game.facebook_url" @click="goto(game.facebook_url)"/>
                <img :src="cafeIcon" class="img-pointer" v-if="game.naver_url" @click="goto(game.naver_url)"/>
            </div>
          </div>
          <div class="description">
            {{ game.description }}
            <span :class="eventTypes[game.event_type]">{{ eventTypes[game.event_type] }}</span>
          </div>
          <div class="game-type">
            <img :src="game.client_end === 1?pcIcon:phoneIcon" />
            <span>{{ gameTypes[game.game_type] }}</span>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import request from '../../../api/request'
import enums from '../../../utils/enums'

export default {
  name: "Grids2",
  data() {
    return {
        gameTypes: enums.GAME_TYPE_CHOICES,
        eventTypes: enums.EVENT_TYPE_CHOICES,
      timer: false,
      facebookIcon: require("../../../assets/images/icon-fb.png"),
      cafeIcon: require("../../../assets/images/icon-cf.png"),
      pcIcon: require("../../../assets/images/icon-pc.png"),
      phoneIcon: require("../../../assets/images/icon-mo.png"),
      gameList: [
      ],
    };
  },
  computed: {
    ...mapGetters(['screenWidth']),
    cardWidth: {
      get() {
        if (this.screenWidth > 1271) return 296;
        else if (this.screenWidth > 689 && this.screenWidth <= 1271)
          return (this.screenWidth - 97) / 2;
        else return this.screenWidth - 20;
      },
      set() {},
    },
  },
  methods: {
      goto (url) {
          window.open(url, '_blank')
      },
      getEvents () {
          let _this = this
          request.get('/events').then(function (res) {
            if (res.status == 200) {
                _this.gameList = res.data.results
            }
          })
      },
  },
  mounted () {
      this.getEvents()
  },
};
</script>
<style>

.img-pointer {
    cursor: pointer;
}
</style>
