
const GAME_TYPE_CHOICES = {
    1: 'RPG',
    2: 'MMORPG',
    3: 'ACTION',
}

const EVENT_TYPE_CHOICES = {
    1: 'NEW',
    2: 'EVENT',
    3: 'HOT',
}

export default {
    GAME_TYPE_CHOICES: GAME_TYPE_CHOICES,
    EVENT_TYPE_CHOICES: EVENT_TYPE_CHOICES,
}