<template>
  <div class="banner">
    <el-carousel indicator-position="inside" trigger="click" v-bind:height="bannerHeight" v-if="banners.length > 0" :interval="10000">
      <el-carousel-item v-for="item in banners" :key="item.id">
        <div class="banner-container" align="center">
          <a :href="item.url" class="banner-item" target="_blank">
            <img v-if="isCollapse" :src="item.cover_mobile" style="max-width:100%"/>
            <img v-else :src="item.cover" />
          </a>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import request from '../../../api/request'

export default {
  name: "Banner",
  data() {
    return {
      banners: [
      ],
    };
  },
  computed: {
    ...mapGetters(['screenWidth']),
    isCollapse () {
        return this.screenWidth < 768;
    },
    bannerHeight() {
      if (!this.isCollapse) {
        return "560px";
      }
      else {
        return this.screenWidth / 8 * 5 + 'px';
      }
    },
  },
  methods: {
      getBanners () {
          let _this = this
          request.get('/banners').then(function (res) {
            if (res.status == 200) {
                _this.banners = res.data.results
            }
          })
      },
  },
  mounted () {
      this.getBanners()
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.banner-container {
  width: 100%;
}
.banner-container img {
  margin: auto;
  // width: 100%;
}
</style>