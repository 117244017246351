var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"game-list-container"},[_c('div',{staticClass:"games-title"},[_vm._v(_vm._s(_vm.$t("이달의 쿠폰")))]),_c('div',{staticClass:"game-cards-container"},_vm._l((_vm.gameList),function(game){return _c('el-card',{key:game.title,attrs:{"body-style":{
        marginTop: '25px',
        marginRight: '12px',
        marginLeft: '12px',
        padding: '15px 0 15px 15px',
        width: _vm.cardWidth - 15 + 'px',
        // height: '90px',
      },"shadow":"never"}},[_c('a',{staticClass:"user-item",attrs:{"href":game.url,"target":"_blank"}},[_c('img',{staticClass:"img-icon",attrs:{"src":game.cover}})]),_c('div',{staticClass:"user-item game-card-small"},[_c('div',[_vm._v(_vm._s(game.title))]),_c('div',{staticClass:"game-type"},[_c('img',{attrs:{"src":game.client_end === 1 ? _vm.pcIcon : _vm.phoneIcon}}),_c('span',[_vm._v(_vm._s(_vm.gameTypes[game.game_type]))])]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t("쿠폰"))+": "+_vm._s(game.code)+" "),_c('button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(game.code),expression:"game.code",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],staticClass:"EVENT",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.$t("복사"))+" ")])])])])}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }