<template>
  <div class="app-container">
    <banner />
    <div class="games-container">
      <!-- <grids1 />
      <grids2 /> -->
      <grids3 />
    </div>
    <div class="games-container">
      <grids4 />
    </div>
  </div>
</template>

<script>
// import { Banner, Grids1, Grids2, Grids3, Grids4 } from "./components";
import { Banner, Grids3, Grids4 } from "./components";

export default {
  name: "Home",
  components: {
    Banner,
    // Grids1,
    // Grids2,
    Grids3,
    Grids4,
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      timer: false,
      user: null,
    };
  },
  computed: {
    cardWidth: {
      get() {
        if (this.screenWidth > 1271) return 296;
        else if (this.screenWidth > 689 && this.screenWidth <= 1271)
          return (this.screenWidth - 97) / 2;
        else return this.screenWidth - 20;
      },
      set() {},
    },
  },
  methods: {
    showMenus() {
      this.menusShow = !this.menusShow;
    },
  },
  watch: {
    screenWidth(val) {
      if (!this.timer) {
        this.screenWidth = val;
        if (this.screenWidth < 768) {
          this.isCollapse = true;
        }
        this.timer = true;
        let that = this;
        setTimeout(function() {
          that.timer = false;
        }, 400);
      }
    },
  },
};
</script>
<style>
.el-card {
  display: inline-block;
}

/* .banner .el-carousel__indicators--horizontal {
  left: 25.3%;
} */
.banner .el-carousel__button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #c9bbb0;
}

.banner .el-carousel__indicator--horizontal {
  padding: 49px 12.5px;
}

.banner .is-active .el-carousel__button {
  background-color: #218cff;
}

.input-container .el-form-item__content,
.input-container .el-input,
.input-container input {
  height: 46px;
}

.el-card {
  border: none;
  background-color: inherit;
  color: inherit;
  border-radius: 0px;
}

.el-card__body {
  background-color: #fff;
}

.img-heading {
  width: 100%;
  display: block;
}
</style>

<style lang="scss">
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.user-item {
  display: inline-block;
}

.user-container {
  max-width: 1281px;
  margin: auto;
  padding-top: 87px;
}

.user-title {
  font-size: 25px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  color: #000000;
  opacity: 0.87;
}

.user-menus {
  float: right;
  text-align: right;
}

.user-menus .user-item {
  width: 100px;
  font-size: 14px;
  font-family: Dotum;
  font-weight: bold;
  color: #444444;
}
.user-detail {
  margin-top: 30px;
}

.input-container {
  width: 628px;
}

.submit-container {
  height: 112px;
  width: 302px;
  float: right;
  margin-left: 24px;
}

.submit-container > button {
  height: 100%;
  background-color: #2e2e2e;
  font-size: 26px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  color: #f8f9fa;
  border: none;
}

.user-detail-butt > button {
  height: 54px;
  border: none;
  background-color: #1484ff;
  font-size: 21px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  color: #f8f9fa;
}

.user-detail-butt img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-bottom: -4px;
  margin-right: 20px;
  margin-left: -20px;
}

.user-detail-butt:nth-child(2) {
  margin-top: 4px;
}
.games-container {
  max-width: 1281px;
  margin: auto;
}
.game-list-container {
  margin-top: 23px;
}

.games-title {
  margin-top: 50px;
  margin-left: 12px;
  font-size: 24px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  color: #000000;
  opacity: 0.87;
}

.game-cards-container {
  max-width: 1305px;
  // display: flex;
  // justify-content: space-between;
}

.description {
  font-size: 12px;
  color: #cccccc;
  margin-top: 5px;
}

.game-type {
  font-size: 12px;
  color: #cccccc;
  margin-top: 10px;
}
.game-type img,
.share-butt img {
  //  height: 10px;
  //  width: 10px;
  margin-right: 10px;
}

.game-type img {
  margin-bottom: -3px;
}

.share-butt {
  float: right;
  // margin-top: 10px;
}

.HOT {
  color: #f37c00;
}

.NEW {
  color: #00f361;
}

.EVENT {
  color: #0075f3;
}

.img-icon {
  width: 74px;
  height: 74px;
  border-radius: 20px;
  margin-bottom: -5px;
  transition: all 0.4s;
}

.img-icon:hover {
  transform: scale(1.2);
}

.game-card-small {
  margin-left: 15px;
  width: 60%;
}

.game-card-small .game-type {
  margin-top: 3px;
}

.game-card-small .description {
  width: 100%;
  margin-top: 14px;
}

.game-card-small .HOT {
  background-color: #f37c00;
  color: #fff;
  padding: 0 5px;
  border-radius: 10px;
}

.game-card-small .NEW {
  background-color: #00f361;
  color: #fff;
  padding: 0 5px;
  border-radius: 10px;
}

.game-card-small .EVENT {
  background-color: #0075f3;
  color: #fff;
  padding: 0 5px;
  border-radius: 10px;
}
.description img {
  margin-right: 6px;
}
.description button,
.description span {
  // float: right;
  border: none;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  .games-title {
    font-size: 18px;
  }
}
</style>
